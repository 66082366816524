AFRAME.registerComponent("ambient-sound", {
    init: function () {
        this.bindMethods();

        this.el.sceneEl.addEventListener("click", this.onFirstClick);
    },

    onFirstClick: function () {
        this.playAmbientSound();
        this.el.sceneEl.removeEventListener("click", this.onFirstClick);
    },

    playAmbientSound: function () {
        this.el.components.sound.playSound();
    },

    /**
     * bind methods used in callbacks
     */
    bindMethods: function () {
        this.onFirstClick = this.onFirstClick.bind(this);
        this.playAmbientSound = this.playAmbientSound.bind(this);
    },
});
